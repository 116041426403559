import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby"
import Project from "../components/project.js";

/*
import PageTransition from 'gatsby-plugin-page-transitions'; 
*/

class Ontwikkeling extends Component {
    render() {
      const {pageData, postData} = this.props;
      return (
       
            <Project title="Ontwikkeling" pageData={pageData} postData={postData} />
       
      );
    }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {

      allWordpressPage(filter: {slug: {eq: "ontwikkeling"}}) {
        edges {
      
          node {
              title
              content
              excerpt
              date
              modified
              slug 
              status
              acf
              {
                keywords,
                description,
                og_img
                {
                  source_url
                },
                
                inleiding
                quote
                youtube_id
                afbeelding
                {
                  caption
                  localFile
                  {
                    
                    childImageSharp
                    {
                      fluid(quality : 100)
                      {
      
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }

        allWordpressPost(
          filter: {
            categories: {elemMatch: {slug: { eq:"ontwikkeling" }}}
          },
          sort: {
            fields: [menu_order]
            order: ASC
            }
        )
        {
          edges
          {
            node
            {
              slug
              title
              id
              acf {
                inleiding
                content
                youtube_id
                img
                {
                  
                  localFile
                  {
                    childImageSharp
                    {
                      fluid(quality : 100)
                      {
      
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }

                  caption
                  
                }
                crop
                {
                  focal_left
                  focal_top
                }
              }	

            
          }
        }
      }
    }
    `}
    render={data => <Ontwikkeling pageData={data.allWordpressPage.edges[0]} postData={data.allWordpressPost.edges} />}
  />
)